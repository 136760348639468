import s from './Hero.scss';
import React from 'react';
import {Image, ImageResizeOptions, Text, TextTypography} from 'wix-ui-tpa';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import classNames from 'classnames';

export const Hero: React.FunctionComponent<IGalleryGlobalProps> = withGlobals((props: IGalleryGlobalProps) => {
  const {heroImageSrc, heroDescription, categoryName} = props.globals;
  const inlineDescription = false;

  return (
    <div className={classNames(s.root, {[s.inline]: inlineDescription})}>
      {heroImageSrc && (
        <div className={s.imageContainer}>
          <Image src={heroImageSrc} resize={ImageResizeOptions.cover} fluid />
        </div>
      )}
      {heroDescription && (
        <div className={s.descriptionContainer}>
          <Text typography={TextTypography.smallTitle} className={s.categoryName}>
            {categoryName}
          </Text>

          <Text className={s.description}>
            <span className={classNames({[s.limitDescriptionLines]: true})}>{heroDescription}</span>
          </Text>
          {/*<span>Read More</span>*/}
        </div>
      )}
    </div>
  );
});
