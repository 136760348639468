import {Filters} from '../../Filters/Filters';
import React from 'react';
import {IGlobals} from '../../../galleryGlobalStrategy';
import {DataHook} from '../../CategoriesApp/CategoriesApp';
import classNames from 'classnames';
import s from '../../GalleryApp/GalleryApp.scss';

export const SideFiltersInLayout = ({
  clearFilters,
  shouldShowClearFilters,
  shouldShowMobile,
  shouldShowSort,
}: IGlobals) => {
  const isDesktop = !shouldShowMobile;
  const shouldRenderDesktopSort = isDesktop && shouldShowSort;

  const classnames = {
    filters: classNames({
      [s.filters]: true,
      [s.withSort]: shouldRenderDesktopSort,
    }),
  };
  return (
    <aside className={classnames.filters} data-hook={DataHook.SideFilters}>
      <Filters shouldShowClearFiltersButton={shouldShowClearFilters} clearFilters={clearFilters} />
    </aside>
  );
};
