import {IGalleryGlobalProps, IGlobals} from '../../galleryGlobalStrategy';
import _ from 'lodash';
import {IGalleryAppProps} from '../CategoriesApp/CategoriesApp';

export const shouldShowGalleryAppInLayout = (isLoaded, {showShowLightEmptyState}: IGlobals) => {
  return isLoaded && !showShowLightEmptyState;
};

export const shouldShowFiltersInLayout = ({filterModels}: IGlobals) => {
  return filterModels.length > 0;
};

export const shouldShowSortInLayout = ({shouldShowSort}: IGlobals) => {
  return shouldShowSort;
};

export const announceIfFilterResultChanged = (prevProps: IGalleryGlobalProps, props: IGalleryAppProps, cb) => {
  const haveFiltersChanged = !_.isEqual(props.globals.filterModels, prevProps.globals.filterModels);

  if (haveFiltersChanged && props.globals.products) {
    cb();
  }
};
