import s from '../../GalleryApp/GalleryApp.scss';
import {Sort} from '../../Sort/Sort';
import React from 'react';

export const SortInLayout = () => {
  return (
    <div className={s.sortRow}>
      <Sort />
    </div>
  );
};
