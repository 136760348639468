import s from '../../GalleryApp/GalleryApp.scss';
import {MobileSort} from '../../MobileSort/MobileSort';
import React from 'react';
import {DataHook} from '../../CategoriesApp/CategoriesApp';
import {MobileFilters} from '../../MobileFilters/MobileFilters';
import {IGlobals} from '../../../galleryGlobalStrategy';

export const MobileFiltersInLayout = ({filterModels, shouldShowSort}: IGlobals) => {
  const shouldShowFilters = filterModels.length > 0;

  return (
    <div className={s.mobileFiltersAndSort} data-hook={DataHook.MobileContainer}>
      {shouldShowFilters && <MobileFilters />}
      {shouldShowSort && <MobileSort areFiltersEnabled={shouldShowFilters} />}
    </div>
  );
};
